import * as React from "react"
import {
  Container,
  Col,
  Row,
  Spacer,
  Typography,
  media,
  Figure,
  Video,
  HurleyThemeProps,
} from "@hurleymc/components"
import styled, { css } from "styled-components"
import { ButtonLink } from "./button-link"
import { Link } from "./link"
import EventDateTime from "./event-date-time"

const Flexbox = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  height: 100%;
`
const Ctas = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    margin-bottom: 1rem;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`

const FigureBlock = styled.div`
  display: none;

  ${media.breakpoint.up(
    "md",
    css`
      display: block;
      height: 100%;
      min-height: 30rem;
    `
  )}
`

const EventLinks = styled.div`
  color: ${({ theme }) => theme.colors.named.curiousBlue};
  display: flex;
  flex-flow: column nowrap;

  ${media.breakpoint.up(
    "lg",
    css`
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
    `
  )}

  > div {
    display: flex;
    flex-flow: row nowrap;
    padding: 0.5rem 0;
    align-items: center;
    > i {
      margin-right: 0.5rem;
      text-align: center;
      width: 24px;
    }
  }
`

const StyledFeaturedContent = styled.div`
  background-color: ${({ theme }: HurleyThemeProps) =>
    theme.colors.named.mineShaft};
  color: ${({ theme }: HurleyThemeProps) =>
    theme.colors.named.mineShaftContrast};

  padding: 2rem 0.5rem;

  ${media.breakpoint.up(
    "lg",
    css`
      padding: 4rem 0 8rem 0;
    `
  )}
`

const StyledFeaturedSummary = styled.div`
  background-color: ${({ theme }: HurleyThemeProps) =>
    theme.colors.named.white};

  padding: 4rem 0.5rem 0;

  ${media.breakpoint.up(
    "lg",
    css`
      margin-top: -4rem;
      border: 1px solid
        ${({ theme }: HurleyThemeProps) => theme.colors.named.silver};
      padding: 2rem;
    `
  )}
`

const StyledIdentifierHeading = styled(Typography)`
  color: ${({ theme }: HurleyThemeProps) =>
    theme.colors.named.mineShaftContrast};
`

const StyledHeader = styled(Typography)`
  color: ${({ theme }: HurleyThemeProps) =>
    theme.colors.named.mineShaftContrast};
`

const StyledSummary = styled(Typography)`
  color: ${({ theme }: HurleyThemeProps) =>
    theme.colors.named.mineShaftContrast};
`

const StyledButtonLink = styled(ButtonLink)`
  display: block;
`

const StyledSpan = styled.span``

const StyledSpanPointer = styled.span`
  cursor: pointer;
`

const scrollToPageBuilder = () => {
  if (typeof document !== "undefined" && document) {
    const el = document.getElementById("page-builder")
    if (el) {
      el.scrollIntoView({ behavior: "smooth" })
    }
  }
}

const EventFeaturedContent = ({ featuredContent, entry, ...props }) => {
  let video: any

  if (featuredContent.videoId) {
    video = [
      {
        __typename: "VideoYouTube",
        videoId: featuredContent.videoId,
      },
    ]
  }

  const startDate = entry.startDate ? new Date(entry.startDate) : false
  const startTime = entry.startTime ? new Date(entry.startTime) : false
  const additionalTime = entry.additionalTime
    ? new Date(entry.additionalTime)
    : false
  const endDate = entry.endDate ? new Date(entry.endDate) : false
  const endTime = entry.endTime ? new Date(entry.endTime) : false

  return (
    <>
      <StyledFeaturedContent>
        <Container>
          <Row xsJustifyContentCenter={true}>
            <Col lg={5 / 6}>
              <Row>
                {video && (
                  <Col md={1 / 2}>
                    <Spacer margin={{ xs: "0 0 2rem", md: "0" }}>
                      <Video video={video} />
                    </Spacer>
                  </Col>
                )}
                {!video &&
                  featuredContent.image &&
                  featuredContent.image.length > 0 && (
                    <Col md={1 / 2}>
                      <FigureBlock>
                        <Figure {...featuredContent.image[0]} />
                      </FigureBlock>
                    </Col>
                  )}
                <Col md={1 / 2}>
                  <Flexbox>
                    {featuredContent.identifierHeading && (
                      <Spacer margin="0 0 1rem">
                        <StyledIdentifierHeading variant="heading-5">
                          {featuredContent.identifierHeading}
                        </StyledIdentifierHeading>
                      </Spacer>
                    )}
                    {featuredContent.header && (
                      <Spacer margin="0 0 1rem">
                        <StyledHeader variant="heading-2">
                          {featuredContent.header}
                        </StyledHeader>
                      </Spacer>
                    )}
                    {featuredContent.summary && (
                      <StyledSummary
                        component="div"
                        dangerouslySetInnerHTML={{
                          __html: featuredContent.summary,
                        }}
                        variant="body"
                      />
                    )}
                    {featuredContent.callToActions &&
                      featuredContent.callToActions.length > 0 && (
                        <Ctas>
                          {featuredContent.callToActions.map((cta, idx) => (
                            <li key={`featuredContentCta${idx}`}>
                              <StyledButtonLink
                                to={cta.ctaLink}
                                type={cta.ctaType}
                                target={cta.ctaTarget}
                              >
                                {cta.ctaText}
                              </StyledButtonLink>
                            </li>
                          ))}
                        </Ctas>
                      )}
                  </Flexbox>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </StyledFeaturedContent>

      <Container>
        <Row xsJustifyContentCenter={true}>
          <Col lg={5 / 6}>
            <StyledFeaturedSummary>
              <Spacer margin="0 0 1.5rem">
                <Typography variant="heading-6">Event</Typography>
              </Spacer>
              <Spacer margin="0 0 1.5rem">
                <Typography variant="heading-2">{entry.title}</Typography>
              </Spacer>

              <EventLinks>
                <div>
                  <i className="fas fa-calendar-alt" />
                  <EventDateTime
                    component={StyledSpan}
                    displayDate={entry.displayDate}
                    startDate={startDate}
                    startTime={startTime}
                    additionalTime={additionalTime}
                    endDate={endDate}
                    endTime={endTime}
                  />
                </div>
                {entry.eventLocationName && (
                  <div>
                    <i className="material-icons">location_on</i>
                    <Typography
                      color="default"
                      component={Link}
                      href={entry.eventUrl}
                      variant="button"
                    >
                      {entry.eventLocationName}
                    </Typography>
                  </div>
                )}
                {entry.to &&
                  entry.to.length > 0 &&
                  entry.to.map((e, idx) => (
                    <div key={`event-row-${idx}`}>
                      {e.externalUrl ? (
                        <i className="fas fa-external-link-alt" />
                      ) : (
                        <i className="fas fa-link" />
                      )}
                      <Typography
                        color="default"
                        component={Link}
                        variant="button"
                        to={e.uri}
                        href={e.externalUrl}
                      >
                        {e.title}
                      </Typography>
                    </div>
                  ))}
                {entry.linkText && (
                  <div>
                    <i className="fas fa-arrow-alt-circle-down" />
                    <Typography
                      color="default"
                      component={StyledSpanPointer}
                      variant="button"
                      onClick={scrollToPageBuilder}
                    >
                      {entry.linkText}
                    </Typography>
                  </div>
                )}
              </EventLinks>
            </StyledFeaturedSummary>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default EventFeaturedContent
