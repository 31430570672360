import React from "react"
import { graphql } from "gatsby"
import styled, { css } from "styled-components"

import {
  Col,
  Container,
  Row,
  Spacer,
  Typography,
  media,
  Figure,
} from "@hurleymc/components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "../components/link"
import PageBuilder from "../components/page-builder"
import EventDateTime from "../components/event-date-time"
import EventFeaturedContent from "../components/event-featured-content"

const FigureBlock = styled.div`
  display: none;

  ${media.breakpoint.up(
    "md",
    css`
      display: block;
      height: 100%;
      min-height: 30rem;
    `
  )}
`

const Table = styled.table`
  border-collapse: separate;
  border-spacing: 0 0.5rem;
  width: 100%;

  td {
    padding: 0;
  }

  td * {
    line-height: 1.25;
  }

  td:first-child {
    padding-right: 0.5rem;
    text-align: center;
  }

  td:last-child {
    width: 100%;
  }

  tr {
    background: transparent !important;
  }

  .tr-pointer:hover {
    cursor: pointer;
  }

  .fas {
    color: ${({ theme }) => theme.colors.named.curiousBlue};
    font-size: 1.25rem;
  }

  .fa-calendar-alt {
    color: ${({ theme }) => theme.colors.named.curiousBlue};
  }
`

const scrollToPageBuilder = () => {
  document.getElementById("page-builder").scrollIntoView({ behavior: "smooth" })
}

const StyledSpan = styled.span``

const StyledSpanPointer = styled.span`
  cursor: pointer;
`

const Event = ({ data }) => {
  const { craft } = data
  const { entries } = craft
  const entry = entries[0]

  const startDate = entry.startDate ? new Date(entry.startDate) : false
  const startTime = entry.startTime ? new Date(entry.startTime) : false
  const additionalTime = entry.additionalTime
    ? new Date(entry.additionalTime)
    : false
  const endDate = entry.endDate ? new Date(entry.endDate) : false
  const endTime = entry.endTime ? new Date(entry.endTime) : false
  const image =
    entry.eventImage && entry.eventImage.length > 0 && entry.eventImage[0].lg
      ? entry.eventImage[0].lg
      : null

  const { eventFeaturedContent } = entry
  const featuredContent =
    eventFeaturedContent?.length > 0 ? eventFeaturedContent[0] : null

  const enableFeaturedContent = true

  return (
    <Layout>
      <SEO title={entry.title} image={image} />
      {enableFeaturedContent && featuredContent && (
        <EventFeaturedContent featuredContent={featuredContent} entry={entry} />
      )}
      <Spacer margin="4rem .5rem">
        <Container>
          <Row>
            <Col md={1 / 2}>
              <Spacer margin="0 0 1.5rem">
                <Typography variant="heading-6">
                  {enableFeaturedContent && featuredContent
                    ? "Event Details"
                    : "Event"}
                </Typography>
              </Spacer>
              {(!enableFeaturedContent || !featuredContent) && (
                <Spacer margin="0 0 1.5rem">
                  <Typography variant="heading-2">{entry.title}</Typography>
                </Spacer>
              )}
              {entry.summary && (
                <Typography
                  component="div"
                  dangerouslySetInnerHTML={{ __html: entry.summary }}
                  variant="body"
                />
              )}
              {(!enableFeaturedContent || !featuredContent) && (
                <Table>
                  <tbody>
                    <tr>
                      <td>
                        <i className="fas fa-calendar-alt" />
                      </td>
                      <td>
                        <EventDateTime
                          component={StyledSpan}
                          displayDate={entry.displayDate}
                          startDate={startDate}
                          startTime={startTime}
                          additionalTime={additionalTime}
                          endDate={endDate}
                          endTime={endTime}
                        />
                      </td>
                    </tr>
                    {entry.eventLocationName && (
                      <tr>
                        <td>
                          <Link href={entry.eventUrl}>
                            <i className="material-icons">location_on</i>
                          </Link>
                        </td>
                        <td>
                          <Typography
                            color="default"
                            component={Link}
                            href={entry.eventUrl}
                            variant="button"
                          >
                            {entry.eventLocationName}
                          </Typography>
                        </td>
                      </tr>
                    )}
                    {entry.to &&
                      entry.to.length > 0 &&
                      entry.to.map((e, idx) => (
                        <tr key={`event-row-${idx}`}>
                          <td>
                            <Link to={e.uri} href={e.externalUrl}>
                              {e.externalUrl ? (
                                <i className="fas fa-external-link-alt" />
                              ) : (
                                <i className="fas fa-link" />
                              )}
                            </Link>
                          </td>
                          <td>
                            <Typography
                              color="default"
                              component={Link}
                              variant="button"
                              to={e.uri}
                              href={e.externalUrl}
                            >
                              {e.title}
                            </Typography>
                          </td>
                        </tr>
                      ))}
                    {entry.linkText && (
                      <tr className="tr-pointer" onClick={scrollToPageBuilder}>
                        <td>
                          <StyledSpan>
                            <i className="fas fa-arrow-alt-circle-down" />
                          </StyledSpan>
                        </td>
                        <td>
                          <Typography
                            color="default"
                            component={StyledSpanPointer}
                            variant="button"
                          >
                            {entry.linkText}
                          </Typography>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              )}
            </Col>
            <Col md={1 / 2}>
              <FigureBlock>
                {entry.eventImage && entry.eventImage.length > 0 && (
                  <Figure {...entry.eventImage[0]} />
                )}
              </FigureBlock>
            </Col>
          </Row>
        </Container>
      </Spacer>
      {entry && entry.pageBuilder && (
        <div id="page-builder">
          <PageBuilder
            pageBuilder={entry.pageBuilder}
            location={{ pathname: entry.uri }}
          />
        </div>
      )}
    </Layout>
  )
}

export const query = graphql`
  query Event($id: [Craft_QueryArgument]!) {
    craft {
      entries(site: "hurleyFoundation", id: $id) {
        __typename
        title
        uri
        ... on Craft_event_event_Entry {
          additionalTime
          displayDate
          endDate
          endTime
          eventFeaturedContent {
            ... on Craft_eventFeaturedContent_featuredContent_BlockType {
              image {
                ... on Craft_AssetInterface {
                  title
                  url
                  xs: url(transform: "utilitySplashXs", immediately: true)
                  sm: url(transform: "utilitySplashSm", immediately: true)
                  md: url(transform: "utilitySplashMd", immediately: true)
                  lg: url(transform: "utilitySplashLg", immediately: true)
                  xl: url(transform: "utilitySplashXl", immediately: true)
                }
              }
              videoId
              identifierHeading
              header
              summary
              callToActions {
                ... on Craft_callToActions_BlockType {
                  ctaText
                  ctaLink
                  ctaType
                  ctaTarget
                }
              }
            }
          }
          eventImage {
            ... on Craft_AssetInterface {
              title
              url
              xs: url(transform: "utilitySplashXs", immediately: true)
              sm: url(transform: "utilitySplashSm", immediately: true)
              md: url(transform: "utilitySplashMd", immediately: true)
              lg: url(transform: "utilitySplashLg", immediately: true)
              xl: url(transform: "utilitySplashXl", immediately: true)
            }
          }
          eventLocationName
          eventUrl
          header
          linkText
          to {
            ...Link
          }
          pageBuilder {
            ...PageBuilder
          }
          startDate
          startTime
          summary
          title
        }
      }
    }
  }
`

export default Event
