import React, { ReactChildren } from "react"
import styled, { css } from "styled-components"
import { Link as GatsbyLink } from "gatsby"
import { lighten, readableColor } from "polished"
import { HurleyThemeProps } from "@hurleymc/components"

const buttonStyles = css`
  display: inline-block;
  font-family: "Open Sans", sans-serif;
  font-size: 0.875rem;
  line-height: 1.375;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  border: 1px solid transparent;
  border-radius: 0;
  padding: 0.75em 1.5em;
  text-align: center;
  transition: all 0.25s ease-in-out;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;

  &:not(:disabled):not(.disabled) {
    cursor: pointer;
  }

  /* Primary */
  background-color: ${({ theme }: HurleyThemeProps) => theme.colors.primary};
  color: ${({ theme }: HurleyThemeProps) =>
    readableColor(
      theme.colors.primary,
      theme.colors.named.white,
      theme.colors.named.black
    )};

  &:hover {
    background-color: ${({ theme }: HurleyThemeProps) =>
      lighten(0.15, theme.colors.primary)};
    color: ${({ theme }: HurleyThemeProps) =>
      readableColor(
        lighten(0.5, theme.colors.primary),
        theme.colors.named.white,
        theme.colors.named.black
      )};
  }

  &.disabled,
  &[disabled] {
    cursor: not-allowed;
    background-color: ${({ theme }: HurleyThemeProps) =>
      lighten(0.15, theme.colors.primary)};
    color: ${({ theme }: HurleyThemeProps) =>
      readableColor(
        lighten(0.5, theme.colors.primary),
        theme.colors.named.white,
        theme.colors.named.black
      )};
  }
`

const StyledGatsbyLink = styled(GatsbyLink)`
  ${buttonStyles}
`

const StyledLink = styled.a`
  ${buttonStyles}
`

interface ButtonLinkProps {
  to: string
  target?: "_blank" | undefined
  type?: "internal" | "external" | undefined
  children: ReactChildren
}

export const ButtonLink = ({
  to,
  target,
  type,
  children,
  ...props
}: ButtonLinkProps) => {
  if (
    type === "internal" &&
    !to.startsWith("http://") &&
    !to.startsWith("https://")
  ) {
    const url = to.startsWith("/") ? to : `/${to}`
    return (
      <StyledGatsbyLink to={url} {...props}>
        {children}
      </StyledGatsbyLink>
    )
  }
  {
    return (
      <StyledLink href={to} {...props} target={target}>
        {children}
      </StyledLink>
    )
  }
}
