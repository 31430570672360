import * as React from "react"
import dateFormat from "dateformat"

import { Typography } from "@hurleymc/components"

const EventDateTime = ({
  component: Link,
  displayDate = null,
  startDate,
  startTime,
  additionalTime = null,
  endDate,
  endTime,
  ...props
}) => (
  <Typography color="default" component={Link} variant="button" {...props}>
    {displayDate
      ? displayDate
      : [
          startDate
            ? startTime
              ? `${dateFormat(startDate, "dddd, mmmm d, yyyy")},`
              : dateFormat(startDate, "dddd, mmmm d, yyyy")
            : "",
          startDate && startTime
            ? startTime.getMinutes() === 0
              ? dateFormat(startTime, "h tt")
              : dateFormat(startTime, "h:M tt")
            : "",
          additionalTime
            ? additionalTime.getMinutes() === 0
              ? dateFormat(additionalTime, " & h tt")
              : dateFormat(additionalTime, " & h:M tt")
            : null,
          endDate || endTime ? " – " : "",
          endDate
            ? endTime
              ? `${dateFormat(endDate, "dddd, mmmm d, yyyy")},`
              : dateFormat(endDate, "dddd, mmmm d, yyyy")
            : "",
          endTime
            ? endTime.getMinutes() === 0
              ? dateFormat(endTime, "h tt")
              : dateFormat(endTime, "h:M tt")
            : "",
        ]
          .join(" ")
          .replace(/\s\s+/g, " ")}
  </Typography>
)

export default EventDateTime
